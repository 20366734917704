<template>
  <section class="settings">
    <template>
      <el-card
        :class="{ dark: false }"
        v-for="(item, index) in pageList"
        v-can="item.permission"
        :key="index"
      >
        <h2 slot="header" class="clearfix">
          {{ item.category }}
        </h2>
        <ul class="settings-list">
          <!-- v-can="hasPermission(route.permission)" -->
          <li
            v-for="(route, index) in item.items"
            @click="onHandler(index)"
            :key="index"
          >
            <router-link
              class="settings-list__item"
              :to="{ path: `/report-category/${route.link}` }"
            >
              <i class="el-icon-s-data icon"></i>
              <span>
                {{ $t(route.name) | truncate(20, "...") }}
              </span>
            </router-link>
          </li>
        </ul>
      </el-card>
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  data() {
    return {
      isActive: 0,
      isCurrentRouter: true,
      pageList: [
        {
          category: i18n.t("message.bonuses"),
          permission: "doctors_report",
          items: [
            {
              name: "message.report_doctors",
              link: "report/report-doctors",
              permission: "doctors_report.index",
            },
            {
              name: "message.report_partner_doctors",
              link: "partnerDoctorReport/index",
              permission: "partner_doctors_report.index",
            },
            {
              name: "message.report_partner_clinics",
              link: "partnerClinicReport/index",
              permission: "partner_clinic_report.index",
            },
            {
              name: "message.report_insurance_company",
              link: "insuranceCompanyReport/index",
              permission: "partner_clinic_report.index",
            },
          ],
        },
        {
          category: i18n.t("message.cashbox"),
          permission: "payment_report",
          items: [
            {
              name: "message.payment_reports",
              link: "paymentReport/index",
              permission: "payment_report.index",
            },
          ],
        },
        {
          category: i18n.t("message.total_reports"),
          permission: "patientResidenceReport",
          items: [
            {
              name: "message.patientResidenceReport",
              link: "patientResidenceReport/index",
              permission: "patientResidenceReport.index",
            },
            {
              name: "message.patientServiceReport",
              link: "patientServiceReport/index",
              permission: "patientServiceReport.index",
            },
            // {
            //   name: "message.dailyProcessReport",
            //   link: "dailyProcessReport/index",
            //   permission: "",
            // },
            // {
            //   name: "message.doctorProcessReport",
            //   link: "doctorProcessReport/index",
            //   permission: "",
            // },
          ],
        },
        {
          category: i18n.t("message.total_reports"),
          permission: "excelReports",
          items: [
            {
              name: "message.excelReport",
              link: "excelReports/index",
              permission: [
                "excelReports.1",
                "excelReports.2",
                "excelReports.3",
                "excelReports.4",
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // mode: "MODE"
    }),
  },
  methods: {
    onHandler(index) {
      this.isActive = index;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  gap: 18px;

  // settings-list
  &-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;

    // settings-list__item
    &__item {
      width: 100%;
      height: 100%;
      padding: 1rem 12px;
      border-radius: 5px;
      border: 1px solid #d1d5db;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      text-align: center;
      color: #92929f;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      span {
        font-size: 16px;
      }

      .icon {
        color: #92929f;
        font-size: 2rem;
        transition: color 0.2s ease-in-out;
      }

      &:hover {
        color: #60a5fa;
        background-color: rgba($color: #fff, $alpha: 0.4);
        border-color: #60a5fa;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .icon {
          color: #60a5fa;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.settings {
  .el-card {
    width: 100%;

    // el-card__header
    &__header {
      margin: 0;
      padding: 12px 20px;

      h2 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #212121a4;
        margin: 0;
      }
    }

    &.dark {
      background-color: #151e34c2 !important;
      border-color: #44587c75 !important;

      h2 {
        color: #fff;
      }

      .el-card__header {
        border-color: #44587c75;
      }

      .settings-list__item {
        border-color: #44587c75;
        background-color: #2a2a3fa6;

        &:hover {
          color: #fff;
          border-color: #fff;

          .icon {
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
